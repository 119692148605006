var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const pdfjsLib = require('pdfjs-dist/legacy/build/pdf');
const PDFs = new Map();
let isFullscreen = false;
const PDF_SCALE_PORTRAIT = 2;
const PDF_SCALE_LANDSCAPE = 1.1;
const PDF_SCALE_FULLSCREEN_PORTRAIT = 5;
const PDF_SCALE_FULLSCREEN_LANDSCAPE = 5;
document.addEventListener('DOMContentLoaded', () => __awaiter(void 0, void 0, void 0, function* () {
    yield initializePdfs();
}));
function initializePdfs() {
    return __awaiter(this, void 0, void 0, function* () {
        const widgets = document.querySelectorAll('.sv-widget-pdfviewer-contentBox-pdfviewer');
        for (const element of widgets) {
            if (!(element instanceof HTMLElement)) {
                return;
            }
            if (pdfjsLib.GlobalWorkerOptions.workerSrc === '') {
                pdfjsLib.GlobalWorkerOptions.workerSrc = element.dataset.workerPath;
            }
            const path = element.dataset.path;
            const widgetId = +element.dataset.wenid;
            const pdf = yield fetchPdf(path);
            PDFs.set(widgetId, {
                pdf,
                'currentPage': 1,
            });
            prepareButtons(element, widgetId);
            const canvas = element.querySelector('.sv-widget-pdfviewer-canvas');
            prepareEvents(canvas, widgetId);
            // Display first page
            displayPdfPageInWidget(widgetId, 1);
        }
    });
}
function prepareButtons(element, widgetId) {
    const pdf = PDFs.get(widgetId);
    const previous = element.querySelector('.pdf-previous');
    const next = element.querySelector('.pdf-next');
    previous.addEventListener('click', event => {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        showPrevious(+event.target.dataset.wenid);
    });
    next.addEventListener('click', event => {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        showNext(+event.target.dataset.wenid);
    });
    togglePreviousButton(widgetId, true);
    if (!hasNext(pdf)) {
        toggleNextButton(widgetId, true);
    }
    const fullscreenBtn = element.querySelector('.pdf-fullscreen');
    fullscreenBtn.addEventListener('click', () => {
        fullscreen(widgetId, true);
    });
}
function prepareEvents(canvas, widgetId) {
    canvas.addEventListener('keydown', (event) => {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        const key = event.key;
        const widgetId = +event.target.dataset.wenid;
        if (key === 'Enter') {
            fullscreen(widgetId, !isFullscreen);
        }
        if (key === 'ArrowLeft') {
            showPrevious(widgetId);
        }
        if (key === 'ArrowRight') {
            showNext(widgetId);
        }
    });
    document.addEventListener('fullscreenchange', () => {
        isFullscreen = document.fullscreenElement !== null;
        displayPdfPageInWidget(widgetId, PDFs.get(widgetId).currentPage);
    });
}
function displayPdfPageInWidget(widgetId, pageNumber) {
    return __awaiter(this, void 0, void 0, function* () {
        const pdf = PDFs.get(widgetId);
        const pdfPage = yield getPage(pdf.pdf, pageNumber);
        let viewport = pdfPage.getViewport({ scale: isFullscreen ? PDF_SCALE_FULLSCREEN_LANDSCAPE : PDF_SCALE_LANDSCAPE });
        const isPortrait = viewport.width > viewport.height;
        if (isPortrait) {
            viewport = pdfPage.getViewport({ scale: isFullscreen ? PDF_SCALE_FULLSCREEN_PORTRAIT : PDF_SCALE_PORTRAIT });
        }
        const canvas = getElementByClassAndWidgetId('sv-widget-pdfviewer-canvas', widgetId);
        if (!(canvas instanceof HTMLCanvasElement)) {
            return;
        }
        // If change to fullscreen, only resize fullscreen element (in case there are multiple pdf widgets on the page)
        if (isFullscreen && canvas !== document.fullscreenElement) {
            return;
        }
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        const ctx = canvas.getContext('2d');
        return pdfPage.render({
            canvasContext: ctx,
            viewport,
        }).promise;
    });
}
function fetchPdf(path) {
    return __awaiter(this, void 0, void 0, function* () {
        return pdfjsLib.getDocument(path).promise;
    });
}
function getPage(pdf, page) {
    return __awaiter(this, void 0, void 0, function* () {
        return pdf.getPage(page);
    });
}
function showPrevious(widgetId) {
    const pdf = PDFs.get(widgetId);
    if (!hasPrevious(pdf)) {
        return;
    }
    pdf.currentPage--;
    displayPdfPageInWidget(widgetId, pdf.currentPage);
    PDFs.set(widgetId, pdf);
    toggleNextButton(widgetId, false);
    if (!hasPrevious(pdf)) {
        togglePreviousButton(widgetId, true);
    }
}
function showNext(widgetId) {
    const pdf = PDFs.get(widgetId);
    if (!hasNext(pdf)) {
        return;
    }
    pdf.currentPage++;
    displayPdfPageInWidget(widgetId, pdf.currentPage);
    PDFs.set(widgetId, pdf);
    togglePreviousButton(widgetId, false);
    if (!hasNext(pdf)) {
        toggleNextButton(widgetId, true);
    }
}
function hasPrevious(pdf) {
    return pdf.currentPage !== 1;
}
function hasNext(pdf) {
    const remainingPages = pdf.pdf.numPages - pdf.currentPage;
    return remainingPages > 0;
}
function togglePreviousButton(widgetId, disable) {
    const prevButton = getElementByClassAndWidgetId('pdf-previous', widgetId);
    if (!(prevButton instanceof HTMLButtonElement)) {
        return;
    }
    prevButton.disabled = disable;
}
function toggleNextButton(widgetId, disable) {
    const nextButton = getElementByClassAndWidgetId('pdf-next', widgetId);
    if (!(nextButton instanceof HTMLButtonElement)) {
        return;
    }
    nextButton.disabled = disable;
}
function fullscreen(widgetId, open) {
    return __awaiter(this, void 0, void 0, function* () {
        const canvas = getElementByClassAndWidgetId('sv-widget-pdfviewer-canvas', widgetId);
        open ? yield canvas.requestFullscreen() : yield document.exitFullscreen();
        canvas.focus();
    });
}
function getElementByClassAndWidgetId(cssClass, widgetId) {
    return document.querySelector('.' + cssClass + '[data-wenid="' + widgetId + '"]');
}
export {};
