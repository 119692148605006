/* eslint-disable import/first */
import '../scss/app.scss';

// foundation
import 'foundation-sites/js/foundation/foundation';
import 'foundation-sites/js/foundation/foundation.topbar';
import 'foundation-sites/js/foundation/foundation.equalizer';
import 'foundation-sites/js/foundation/foundation.interchange';

import './lib/jquery.colorbox';

// jQuery
const jQueryTemp = require('jquery');
// .min version throws error with Foundation
const jQuery = jQueryTemp;
window.$ = jQueryTemp;
window.jQuery = jQueryTemp;
jQuery.migrateMute = true;

import 'chosen-js';
import './app/rem.min';

import '../../../platform/widgets/view/comparevehicles/js/base';
import '../../../platform/widgets/view/pdfviewer/ts/base.ts';

$(() => {
    $(document).foundation();

    const initLayoutSizeObject = function () {
        let viewMode;

        const resizeHandler = function () {
            setVehicleCompareFixedRightPos();

            const prevViewMode = viewMode;
            // Check if the media query is activated
            if (matchMedia(window.Foundation.media_queries.medium).matches) {
                viewMode = 'large';
            } else {
                viewMode = 'small';
            }

            // (re)initialize sidebar scrolling if view mode changes
            if (prevViewMode !== viewMode) {
                //
            }
        };

        const setVehicleCompareFixedRightPos = function () {
            let nOffsetRight = 0;
            const ele = $('#vehicle-compare-link');
            if (ele.length === 0) {
                return;
            }
            ele.removeAttr('style');
            const nLayoutOffset = $(window).width() - $('#content').width();
            if (nLayoutOffset > 0) {
                nOffsetRight = (nLayoutOffset / 2);
                // calc rotate offset
                nOffsetRight -= 83;
                ele.css('right', `${nOffsetRight}px`);
            }
        };

        resizeHandler();
        $(window).resize(resizeHandler);
    };

    initLayoutSizeObject();

    if ($('.gallery').length > 0) {
        $('a.colorbox').colorbox({
            rel: 'vehicles',
            current: 'Bild {current} von {total}',
            previous: 'Zur&uuml;ck',
            next: 'Weiter',
            close: 'Schliessen',
            xhrError: 'Fehler beim Laden des Inhalts.',
            imgError: 'Fehler beim Laden des Bildes.',
            maxHeight: '100%',
            maxWidth: '100%',
        });
    }
    if ($('[name="vehiclelisting-filter"]').length > 0) {
        $('.chosen-select').chosen({
            disable_search_threshold: 10,
            search_contains: true,
            no_results_text: 'Keine Ergebnisse f&uuml;r',
            placeholder_text_single: 'Bitte w&auml;hlen...',
            placeholder_text_multiple: 'Bitte w&auml;hlen...',
        });
    }

    const handleContactForm = () => {
        const formList = document.querySelectorAll('.sv-widget-content form');

        for (const form of formList) {
            form.addEventListener('submit', () => {
                const submitBtn = form.querySelector('input[type="submit"]');
                submitBtn.disabled = true;

                // In case of incorrect input
                // the user has to re-submit the form again.
                setTimeout(() => {
                    submitBtn.disabled = false;
                }, 2000);
            });
        }
    };

    handleContactForm();
});
